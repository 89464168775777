.indicator {
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

.activeColor {
  background-color: #3ecd7b;
}

.inactiveColor {
  background-color: #a8b2c2;
}
