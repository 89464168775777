.container {
  .title {
    margin-top: 5px;
    margin-bottom: 25px;
    font-weight: 500;
    font-size: 12px;
    color: #69768b;
  }

  .subTitle {
    margin-top: 15px;
    margin-bottom: 25px;
    font-weight: 400;
    font-size: 12px;
    color: #69768b;
  }

  .contentRowLeft:nth-child(odd) {
    border-right: solid 1.5px #f5f6f7;
    padding-right: 30px;
  }
  .contentRowLeft:nth-child(even) {
    padding-left: 30px;
  }

  .deviceContainer {
    padding: 10px 0;

    .left {
      .icon {
        width: 32.75px;
        height: 32.75px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
      }
      label {
        font-weight: 500;
        font-size: 11px;
        color: #383941;
        line-height: 10px;
      }
    }
    .right {
      .progress {
        height: 14px;
        border-radius: 4px;
        overflow: hidden;
        position: relative;
        div {
          position: absolute;
          right: 0;
          height: 100%;
        }
      }
      label {
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #69768b;
      }
    }
  }
}

@media (max-width: 992px) {
  .container {
    .contentRowLeft:nth-child(odd) {
      border-right: none;
      padding-left: 20px;
      padding-right: 20px;
    }
    .contentRowLeft:nth-child(even) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
